import React, { Component, createRef } from 'react';

import ReactDOM from 'react-dom';
import commonFunction from '../../../lib/utils/common';

export default class NationalityDropdownComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            shouldShowFlag: false,
            selectedNationality: null,
            openList: false,
            countryFlag: [],
            indexDropdown: -1,
            code: {}
        }
        this.statusFilterLayerRef = createRef(null)
    }

    componentDidMount = () => {
        this.setState({ shouldShowFlag: true, openList: false });
        document.addEventListener('mousedown', this.handleClickOutsideOfonStatusFilterClick, true);
        document.addEventListener('keydown', this.handleTabOutside, true);
    }

    componentWillUnmount = () => {
        document.removeEventListener('mousedown', this.handleClickOutsideOfonStatusFilterClick, true);
        document.removeEventListener('keydown', this.handleTabOutside, true);
    }

    // onStatusFilterClick = () => {
    //     if (!this.state.openList) {
    //         this.setState({ openList: true });
    //     }
    //     else {
    //         this.setState({ openList: false, shouldShowFlag: true });
    //     }
    // }

    handleClickOutsideOfonStatusFilterClick = (event) => {

        // const statusFilterLayerNode = ReactDOM.findDOMNode(this.statusFilterLayerRef);
        // const statusFilterNode = ReactDOM.findDOMNode(this.statusFilterRef);

        /* if (statusFilterNode !== null && statusFilterNode.contains(event.target)) {
            return;
        } else */ if (this.statusFilterLayerRef.current && !this.statusFilterLayerRef.current.contains(event.target)) {
            this.setState({ openList: false, shouldShowFlag: true });
            // document.removeEventListener('click', this.handleClickOutsideOfonStatusFilterClick, true);
        }
    }

    handleTabOutside = (e) => {
        if (e.key !== "Tab") {
            return
        }
        this.handleClickOutsideOfonStatusFilterClick(e)
    }

    onInputClickHandler = () => {
        this.setState({ openList: true, shouldShowFlag: false }, () => {
            let countryFlag1 = this.props.countryFlag.filter(ele => {
                return ele.value.toLowerCase().includes(this.props.searchText.toLowerCase());
            });
            countryFlag1.sort((a, b) => { return a.value - b.value });
            this.setState({ countryFlag: countryFlag1, showNoResultFound: false });
            if (countryFlag1.length > 0) {
                this.setState({ showNoResultFound: false });
            } else {
                this.setState({ showNoResultFound: true });
            }
        });
    }

    highlightData(id, parentElement) {
        let innerContent = document.getElementById(parentElement).children
        for (let i = 0; i < innerContent.length; i++) {
            let selectedElement = innerContent[i];
            selectedElement.style.backgroundColor = (i == id) ? "#ccc" : "#fff";
        }
        innerContent[id].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    }

    onKeyDownArrow = (event, parentElementId, list, key) => {
        let code = {}
        let indexDropdown = this.state.indexDropdown
        switch (event.which) {
            case 38: // up
                indexDropdown--;
                if (indexDropdown < 0) { indexDropdown = 0 }
                this.highlightData(indexDropdown, parentElementId)
                code = (list.length >= indexDropdown) ? list[indexDropdown] : {};
                break;
            case 40: // down
                indexDropdown++;
                if (indexDropdown >= list.length - 1) { indexDropdown = list.length - 1 }
                this.highlightData(indexDropdown, parentElementId)
                code = (list.length >= indexDropdown) ? list[indexDropdown] : {};
                break;

            case 13: //enter
                if (this.state?.code?.contry_isd) {
                    //   dataListSelectHandler({ target: { value: "" } }, state.code, 0)
                    this.setState({ shouldShowFlag: true });
                    this.props.selectNationality(this.state.code)
                }
                indexDropdown = -1;
                break;
            case 9: //tab
                if (this.state?.code?.contry_isd) {
                    //   dataListSelectHandler({ target: { value: "" } }, state.code, 0)
                    this.setState({ shouldShowFlag: true });
                    this.props.selectNationality(this.state.code)
                }
                indexDropdown = -1;
                break;
            default:
        }
        this.setState({ code: code, indexDropdown: indexDropdown })
    }

    render() {
        return (
          <div
            onClick={
              this.onStatusFilterClick
            } /* ref={this.statusFilterLayerRef} */
          >
            <span
              className={
                this.props.isSignUpNationality
                  ? this.props.signup_nationality_class
                  : this.props.isB2B
                  ? this.props.signup_nationality_class_2
                  : this.props.vt_country_select_main_container
              }
            >
              <span
                /* role="presentation" */ className={
                  this.props.vt_country_select_container
                }
              >
                <input
                  className={
                    this.state.shouldShowFlag
                      ? this.props.vt_inspira_country_main
                      : this.props.vt_inspira_country_main +
                        this.props.vt_country_selected
                  }
                  onClick={() => {
                    this.setState(
                      { openList: true, shouldShowFlag: false },
                      () => {
                        let countryFlag1 = this.props.countryFlag.filter(
                          (ele) => {
                            return ele.value
                              .toLowerCase()
                              .includes(this.props.searchText.toLowerCase());
                          }
                        );
                        countryFlag1.sort((a, b) => {
                          return a.value - b.value;
                        });
                        this.setState({
                          countryFlag: countryFlag1,
                          showNoResultFound: false,
                        });
                        if (countryFlag1.length > 0) {
                          this.setState({ showNoResultFound: false });
                        } else {
                          this.setState({ showNoResultFound: true });
                        }
                      }
                    );
                  }}
                  autoComplete="off"
                  // title={this.props.searchText}
                  onFocus={() => {
                    this.onInputClickHandler();
                  }}
                  onKeyDown={(e) => {
                    this.onKeyDownArrow(
                      e,
                      "isd_country_dropdown_parent",
                      this.state.countryFlag
                    );
                  }}
                  onChange={(e) => {
                    this.props.onChangeNationalityCode(e);
                    let countryFlag1 = this.props.countryFlag.filter((ele) => {
                      return ele.value
                        .toLowerCase()
                        .startsWith(e.target.value.toLowerCase());
                    });
                    countryFlag1.sort((a, b) => {
                      return a.value - b.value;
                    });
                    // console.log("Backspaceeee", countryFlag1)
                    this.setState({ countryFlag: countryFlag1 }, () => {
                      this.setState({ openList: true, shouldShowFlag: false });
                    });
                    if (countryFlag1.length > 0) {
                      this.setState({ showNoResultFound: false });
                    } else {
                      this.setState({ showNoResultFound: true });
                    }
                  }}
                  // onKeyDown={(e) => {
                  //     const key = e.key;
                  //     if (key === "Backspace" || key === "Delete") {
                  //         // setshouldShowFlag(false)
                  //         this.setState({ shouldShowFlag: false });
                  //     }
                  //     if (key === 'Tab') {
                  //         // setopenList(false)
                  //         // setshouldShowFlag(true)
                  //         this.setState({ openList: false, shouldShowFlag: true });
                  //     }
                  // }}
                  value={
                    this.props.searchText !== ""
                      ? this.props.searchText
                      : this.props.selectedNationality.value
                  }
                  placeholder={this.props.placeholder}
                />
              </span>
              {!commonFunction.isValueEmpty(this.props.isError) &&
          this.props.isError === true ? (
            <div
              style={{
                fontsize: '11px',
                color: '#e8664e',
                lineheight: '13px',
                margintop: '10px',
              }}
            >
              <span>
                {!commonFunction.isValueEmpty(this.props.errorMessage)
                  ? this.props.errorMessage
                  : 'Invalid field'}
              </span>
            </div>
          ) : null}
              {/* {
                        this.state.showNoResultFound && (
                            <div>
                                No Result Found !
                            </div>
                        )
                    } */}

              {this.state.openList && !this.state.shouldShowFlag && (
                <div
                  className={
                    this.state.countryFlag.length <= 4
                      ? this.props.vt_country_select_list_container
                      : this.props.vt_country_select_list_container
                  }
                  ref={this.statusFilterLayerRef}
                  id="isd_country_dropdown_parent"
                >
                  {this.state.showNoResultFound && (
                    <div
                      style={{ padding: "8px" }}
                      className={this.props.no_result_found}
                    >
                      {"No Result Found !"}
                    </div>
                  )}

                  {this.state.countryFlag.map((ele, index) => {
                    return (
                      <div
                        className={this.props.country_name_code}
                        key={index}
                        onClick={() => {
                          this.setState({ shouldShowFlag: true });
                          this.props.selectNationality(ele);
                        }}
                      >
                        {" "}
                        {/* this.setState({ shouldShowFlag: true }) */}
                        {ele.flag && (
                          <img
                            style={{ height: "20px", width: "25px" }}
                            src={
                              ele.flag !== "NULL" &&
                              ele.flag !== null &&
                              ele.flag
                            }
                            alt=" "
                          />
                        )}
                        <span>{ele.value}</span>
                        {ele.currency && (
                          <span className={this.props.abc}>{ele.currency}</span>
                        )}
                        {ele.currencyCode && <span>{ele.currencyCode}</span>}
                      </div>
                    );
                  })}
                </div>
              )}
            </span>
          </div>
        );
    }
}