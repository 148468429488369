export const invalidNameChars = /[^a-zA-Z\s]/g;
export const multipleSpaces = /\s+/g;
export const invalidAlphanumeric = /[^a-zA-Z0-9\s]/g;
export const passportNumberReg = /[^a-zA-Z0-9]/g;
export const specialFareReg = /[^a-zA-Z0-9]+/g;
export const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
export const leadingSpaces = /^\s+/;
export const leadingTrailingSpaces = /^\s*|\s*$/g;
export const onlyNumber = /^\d+$/;
export const numberWithDotValue = /[^0-9.]/g;
export const allowedCharactersRegex = /^(?![IQWT\s])[A-Z0-9\-\/\s]{1,20}$/;
export const invalidCharactersRegex = /[^a-zA-Z0-9\-\/\s]/;
export const disallowedFirstCharacterRegex = /^[IQWTiqwt\s]/;
export const isdCodeRegex = /\D/g;