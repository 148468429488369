export const offlineCreateEnquiry = (req) => {
  console.log('offlineCreateEnquiry', req)
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      device_type: localStorage.getItem('deviceType'),
    },
    body: JSON.stringify(req),
  }
  return fetch('/shared/api/offline/enquiry/createEnquiry', requestOptions).then((response) =>
    response.json()
  )
}

export const offlineGetEnquiry = (req) => {
  console.log('offlineGetEnquiry', req)
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      device_type: localStorage.getItem('deviceType'),
    },
    body: JSON.stringify(req),
  }
  return fetch('/shared/api/offline/enquiry/getEnquiry', requestOptions).then((response) =>
    response.json()
  )
}

export const offlineDeleteEnquiry = (req) => {
  console.log('offlineGetEnquiryDelete', req)
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      device_type: localStorage.getItem('deviceType'),
    },
    body: JSON.stringify(req),
  }
  return fetch('/shared/api/offline/enquiry/updateEnquiry', requestOptions).then((response) =>
    response.json()
  )
}

export const offlineSendEnquiry = (req) => {
  console.log('offlineGetEnquiryDelete', req)
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      device_type: localStorage.getItem('deviceType'),
    },
    body: JSON.stringify(req),
  }
  return fetch('/shared/api/offline/enquiry/sendEnquiry', requestOptions).then((response) =>
    response.json()
  )
}

export const offlineTraveller = (req) => {
  console.log('offlineGetEnquiryDelete', req)
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      device_type: localStorage.getItem('deviceType'),
    },
    body: JSON.stringify(req),
  }
  return fetch('/shared/api/offline/enquiry/addTraveller', requestOptions).then((response) =>
    response.json()
  )
}

export const offlineBook = (req) => {
  console.log('bookkkkkkkk', req)
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      device_type: localStorage.getItem('deviceType'),
    },
    body: JSON.stringify(req),
  }
  return fetch('/shared/api/offline/enquiry/bookEnquiry', requestOptions).then((response) =>
    response.json()
  )
}
