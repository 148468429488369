import GuestDetail from "./theme1"
import Theme4 from "./theme4";

export function GuestDetails(props) {
    const { theme } = props
    switch (theme) {
        case 'theme1':
            return <GuestDetail {...props} />
        case 'theme4':
            return <Theme4 {...props} />
        default:
            return <GuestDetail {...props} />
    }
}

export default GuestDetails;