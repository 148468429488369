export const addClient = (req) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req)
    };
    return fetch('/shared/api/clients/addclients', requestOptions).then((response) => response.json())
}



export const updateClient = (req) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req)
    };
    return fetch('/shared/api/clients/updateclients', requestOptions).then((response) => response.json())
}

export const getClient = (req) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req)
    };
    return fetch('/shared/api/clients/getclients', requestOptions).then((response) => response.json())
}