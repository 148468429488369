export default function ModalPopupComponent(props) {
  let styles = props ? props.modalCss : { "modal_container": "" };
  // document.querySelector("body").style.overflow =    true  ? "hidden" : "auto" ;
  return (
    <>
      {props.isPopup === true ? (
        <div className={styles.modal_overflow}> 
        <div className={styles.modal_container}>
          <div className={styles.modal_header}>
            {
              props?.showCross ?
              <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="18px" onClick={props.closePopup}>
                <path fill-rule="evenodd" fill="rgb(255, 255, 255)" d="M0.830,0.983 C0.313,1.037 -0.065,1.526 -0.015,2.074 C0.007,2.313 0.108,2.535 0.272,2.699 L6.646,9.486 L0.272,16.256 C-0.098,16.649 -0.098,17.285 0.272,17.677 C0.641,18.069 1.239,18.069 1.608,17.677 L1.608,17.677 L7.997,10.890 L14.372,17.677 C14.741,18.069 15.339,18.069 15.708,17.677 C16.077,17.285 16.077,16.649 15.708,16.256 L15.708,16.256 L9.319,9.486 L15.708,2.699 C16.081,2.316 16.092,1.683 15.731,1.287 C15.545,1.083 15.286,0.971 15.018,0.983 C14.774,0.989 14.543,1.095 14.372,1.279 L7.997,8.051 L1.608,1.279 C1.430,1.088 1.186,0.981 0.933,0.983 C0.898,0.981 0.864,0.981 0.830,0.983 Z"/>
                </svg> :
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="24" onClick={props.closePopup} >
          <path fill-rule="evenodd" fill="#FFF" d="m14.442 3.241-8.833 8.747 8.836 8.75c.751.743.751 1.95 0 2.694a1.938 1.938 0 0 1-2.721 0L1.536 13.343a1.878 1.878 0 0 1-.529-.99 1.887 1.887 0 0 1 .526-1.718L11.721.547a1.938 1.938 0 0 1 2.721 0 1.89 1.89 0 0 1 0 2.694z"/>
          </svg>
          }

           {/* <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="18px">
                <path fill-rule="evenodd" fill="rgb(255, 255, 255)" d="M0.830,0.983 C0.313,1.037 -0.065,1.526 -0.015,2.074 C0.007,2.313 0.108,2.535 0.272,2.699 L6.646,9.486 L0.272,16.256 C-0.098,16.649 -0.098,17.285 0.272,17.677 C0.641,18.069 1.239,18.069 1.608,17.677 L1.608,17.677 L7.997,10.890 L14.372,17.677 C14.741,18.069 15.339,18.069 15.708,17.677 C16.077,17.285 16.077,16.649 15.708,16.256 L15.708,16.256 L9.319,9.486 L15.708,2.699 C16.081,2.316 16.092,1.683 15.731,1.287 C15.545,1.083 15.286,0.971 15.018,0.983 C14.774,0.989 14.543,1.095 14.372,1.279 L7.997,8.051 L1.608,1.279 C1.430,1.088 1.186,0.981 0.933,0.983 C0.898,0.981 0.864,0.981 0.830,0.983 Z"/>
                </svg> */}

          <p className={styles.header_text}>{props.heading || ''}</p>

          </div>
              
                {props.children}
           {
            props.showBtn &&
           <button className={styles.applyNow} onClick={props.closePopup}>Apply Now</button>
           }
          </div>
        </div>
      ) : null}
    </>
  )
}