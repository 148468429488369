import React, { Component, useState } from "react";

import commonFunction from "../../../lib/utils/common";
import { debounce } from "lodash";
import { email } from "@/data/siteMetadata";
// import { getGuestList } from "../../../../common/lib/guestSuggest";
import {
  getClient,
  addClient,
  updateClient,
} from "../../../../common/lib/clients/clientsAPI";
export default class GuestDetail extends Component {
  constructor(props) {
    super(props);
    console.log("erroermsx", this.props.props.errorMessage);
    this.state = {
      setAddGuest: [],
      setGuest: [],
      guestId: "",
      isAddTab: true,
    };
  }
  searchGuest = debounce((event) => {
    let requestedData = {
      active: true,
      search_text: event.target.value,
      client_id: "",
    };
    getClient(requestedData).then((data) =>
      this.setState({ setGuest: data?.list })
    );
  }, 200);

  addGuest = async (first_name, last_name, userid, email, nationality) => {
    // console.log(first_name,last_name,userid,email)
    let isAdd = true;
    await this.setState({
      setAddGuest: [
        {
          f_name: first_name,
          l_name: last_name,
          mail: email,
          userid: userid,
          nationality: nationality,
        },
      ],
    });
    await this.setState({ guestId: userid });
    await this.props.props.addGuestId(this.state.guestId);
    (await this.props?.props?.clientNationality)
      ? this.props?.props?.clientNationality(nationality, userid, email, isAdd)
      : null;
    await this.setState({ isAddTab: false });
  };

  removeGuest = async (first_name, last_name, userid, email, nationality) => {
    console.log(
      "first_name,last_name,userid,email,nationality",
      first_name,
      last_name,
      userid,
      email,
      nationality
    );
    let isAdd = false;
    this.setState({ setAddGuest: (this.state.setAddGuest = 0) });
    this.setState({ guestId: "" });
    await this.props.props.removeGuestId(this.state.guestId);
    (await this.props?.props?.clientNationality)
      ? this.props?.props?.clientNationality(nationality, userid, email, isAdd)
      : null;
    await this.setState({ isAddTab: true });
  };
  render() {
    console.log("===>", this.state?.setGuest);
    console.log("this.statesvdsdv", this.props);
    return (
      <div
        ref={this.props.props.guestWrapper}
        className={this.props?.props?.styles?.collapsible_title_add}
      >
        <div className="flex">
          <div className="fname form-inputs addguestbtn-width" id="htlguestdiv">
            <div className={this.props.props.styles.addguestbtn}>
              <div className={this.props.props.styles.trav_flex}>
                {/* <label for="fname" className="lablename-htl">
                  {" "}
                  &nbsp;
                </label> */}
                <span className={this.props.props.styles.travtext}>
                  {"  Traveler:"}{" "}
                </span>

                {this.state?.setAddGuest[0]?.f_name && (
                  <p className={this.props.props.styles.travNameCap}>
                    {(this.state?.setAddGuest[0]?.f_name).toUpperCase()}{" "}
                    {this.state?.setAddGuest[0]?.l_name
                      ? (this.state?.setAddGuest[0]?.l_name).toUpperCase()
                      : ""}
                  </p>
                )}
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => this.props.props?.onClickTraveller()}
                >
                  <button
                    type="button"
                    className={this.props.props?.styles?.traveller_button}
                    id=""
                  >
                    {this.props.props.openTraveler ? "-" : "+"}
                  </button>
                  <span className={this.props.props.styles.guesttext}>
                    {"  Add Client(s)"}{" "}
                  </span>
                </div>
              </div>
              {!commonFunction.isValueEmpty(this.props.props.isError) &&
              this.props.props.isError === true ? (
                <div
                  style={{
                    fontSize: "11px",
                    color: "#e8664e",
                    marginTop: "3px",
                  }}
                >
                  <span>
                    {!commonFunction.isValueEmpty(this.props.props.errorMessage)
                      ? this.props.props.errorMessage
                      : ""}
                  </span>
                </div>
              ) : null}
              <div id="guest_count" className="guest_counts" value=""></div>
              {this.props.props?.openTraveler && (
                <div
                  id="myGuest"
                  className={this.props.props.styles.dropdown_Guest_container}
                >
                  <div className={this.props.props.styles.inputsearch}>
                    <input
                      type="text"
                      id="myInputNation"
                      onkeyup="searchFuest(event)"
                      onChange={(event) => this.searchGuest(event)}
                      placeholder="Search Client"
                    />
                  </div>
                  <div
                    id="guestlist"
                    className={this.props.props.styles.gest_container}
                  >
                    {this.state?.setAddGuest?.length ? (
                      <div className={this.props.props.styles.travel_gest}>
                        <p>Remove Client</p>
                        <div className={this.props.props.styles.travlr_scroll}>
                          {this.state?.setAddGuest?.map((item) => (
                            <div
                              className={this.props.props.styles.travlr_wrap}
                            >
                              <div
                                className={this.props.props.styles.travlr_name}
                              >
                                <span>{item.mail}</span>
                                <br />
                                <span
                                  className={
                                    this.props.props.styles.client_name
                                  }
                                >
                                  {item.f_name + " " + item.l_name}
                                </span>
                              </div>
                              <button
                                className={this.props.props.styles.travlr_btn}
                                onClick={() => {
                                  this.removeGuest(
                                    item.f_name,
                                    item.l_name,
                                    item.userid,
                                    item.mail,
                                    item.nationality
                                  );
                                }}
                              >
                                -
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}

                    {this.state?.setGuest?.length && this.state?.isAddTab ? (
                      <div className={this.props.props.styles.travel_gest}>
                        <p>Add Client</p>
                        <div className={this.props.props.styles.travlr_scroll}>
                          {this.state?.setGuest?.map((item) => (
                            <div
                              className={this.props.props.styles.travlr_wrap}
                              key={item.id}
                            >
                              {/* {console.log("item",item)} */}
                              <div
                                className={this.props.props.styles.travlr_name}
                              >
                                <span> {item.email}</span>
                                <br />
                                <span>
                                  Client:{" "}
                                  <span
                                    className={
                                      this.props.props.styles.client_name
                                    }
                                  >
                                    {item.first_name + " " + item.last_name}
                                  </span>
                                </span>
                              </div>
                              <button
                                className={this.props.props.styles.travlr_btn}
                                onClick={() => {
                                  this.addGuest(
                                    item.first_name,
                                    item.last_name,
                                    item._id,
                                    item.email,
                                    item.nationality
                                  );
                                }}
                              >
                                +
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
