import NationalityDropdown1 from "./theme1";
import NationalityDropdown4 from "./theme4";
import NationalityDropdown2 from "./theme2";

export default function NationalityDropdown(props){
    const { theme } = props
    switch (theme) {
      case 'theme1':
          return <NationalityDropdown1 {...props}/>
      case 'theme2':
          return <NationalityDropdown2 {...props}/>    
      case 'theme4':
        return <NationalityDropdown4 {...props}/>
      default:
          return <NationalityDropdown1 {...props}/>
    }
}



